import { transformAsCurrency } from '~/utils/functions';
import { calculaReducaoBaseCalculo } from './ReducaoBaseCalculo';
import { CalculaValorICMS } from './CalculaValorICMS';
/**
 * Efetua Cálculo dos campos val_icms, val_bc_icms, val_outros, val_isento e val_fcp_icms
 */

interface ValorTabelaEVendaTotaResponse {
  val_icms: number;
  val_bc_icms: number;
  val_outros: number;
  val_isento: number;
  val_fcp_icms: number;
}

export function CalculaICMS(
  flgOrgaoPublico: boolean,
  valorTabelaLiquida: number,
  valorReducaoBaseCalculo: number,
  tipoTributacao: number,
  percentualFCPICMS: number,
  codSituacaoTributaria: string,
  flagIgnoraIcmsNfCliente: boolean,
  tipoRegime: number,
  flagCalculaST: boolean,
  flagProducaoPropria: boolean,
  valIcms: number,
): ValorTabelaEVendaTotaResponse {
  let valorBaseCalculoICMS = 0;
  let valorICMS = 0;
  let valorOutros = 0;
  let valorIsento = 0;
  let valorICMSFCP = 0;

  // Quando o cliente for um órgão público, não há base de cálculo nem ICMS, os valores vão para Isento.
  if (flgOrgaoPublico) {
    valorIsento = valorTabelaLiquida;
  } else {
    if ([0, 2].includes(tipoTributacao)) {
      valorBaseCalculoICMS = calculaReducaoBaseCalculo(
        valorTabelaLiquida,
        valorReducaoBaseCalculo,
      );

      valorICMSFCP = parseFloat(
        String(valorBaseCalculoICMS * (percentualFCPICMS / 100)),
      );
    }
    if (![0, 1, 2, 8].includes(tipoTributacao)) {
      valorOutros = valorTabelaLiquida - valorBaseCalculoICMS;
    } else {
      valorIsento = parseFloat(
        String(valorTabelaLiquida - valorBaseCalculoICMS),
      );
    }
    if (
      tipoRegime !== 2 &&
      (flagCalculaST || flagProducaoPropria) &&
      tipoTributacao === 3 &&
      codSituacaoTributaria !== '060'
    ) {
      valorBaseCalculoICMS = calculaReducaoBaseCalculo(
        valorTabelaLiquida,
        valorReducaoBaseCalculo,
      );
    }
  }

  if (flagIgnoraIcmsNfCliente) {
    valorOutros = valorTabelaLiquida;
    valorBaseCalculoICMS = 0;
  }
  valorICMS = CalculaValorICMS(
    valorTabelaLiquida,
    valIcms,
    valorReducaoBaseCalculo,
    tipoTributacao,
    false,
    true,
  );

  return {
    val_icms: transformAsCurrency(valorICMS || 0),
    val_bc_icms: transformAsCurrency(valorBaseCalculoICMS || 0),
    val_outros: transformAsCurrency(valorOutros || 0),
    val_isento: transformAsCurrency(valorIsento || 0),
    val_fcp_icms: transformAsCurrency(valorICMSFCP || 0),
  };
}
