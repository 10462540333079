import { toast } from 'react-toastify';
import api from '~/services/api';
import { Produto, CfopData, TribData } from '../../protocols';
import { CalculaICMS } from './CalculaICMS';
import { CalculaValorICMS } from './CalculaValorICMS';

interface CalculosDaTabelaProps {
  item: Produto;
  qtd_entrada: number;
  val_tabela_str: string;
  val_desconto_str: string;
  val_acrescimo_str: string;
  flg_orgao_publico: boolean;
  tipo_tributacao: number;
  val_reducao_base_calculo: number;
  cod_situacao_tributaria: number;
  cod_tributacao: number;
  per_icms: number;
  tipo_regime: number;
  des_sigla: string;
  cfop: string;
  des_cfop: string;
  bonificado: boolean;
  lastIndex: number;
  produtoSelecionado?: Produto;
  cfopData?: CfopData;
  tribData?: TribData;
}

async function getPerFCP(cod_ncm: string, des_sigla: string) {
  try {
    const { data } = await api.get('/item/per-fcp', {
      params: {
        cod_ncm,
        des_sigla,
      },
    });
    return data.data[0].per_fcp_icms || 0;
  } catch (error: any) {
    if (error.data && error.data.message) {
      toast.error(error.data.message);
      return 0;
    }
    toast.error(String(error));
    return 0;
  }
}

function valToNumber(
  val_tabela: string,
  val_desconto: string,
  val_acrescimo: string,
) {
  let formatedValTabela = val_tabela || 0;
  let formatedAcrescimo = val_acrescimo || 0;
  let formatedDesconto = val_desconto || 0;

  if (val_tabela && val_tabela !== '') {
    formatedValTabela = Number(val_tabela.replace('.', '').replace(',', '.'));
  }
  if (val_acrescimo && val_acrescimo !== '') {
    formatedAcrescimo = Number(
      val_acrescimo.replace('.', '').replace(',', '.'),
    );
  }
  if (val_desconto && val_desconto !== '') {
    formatedDesconto = Number(val_desconto.replace('.', '').replace(',', '.'));
  }
  return {
    val_tabela: Number(formatedValTabela),
    val_desconto: Number(formatedDesconto),
    val_acrescimo: Number(formatedAcrescimo),
  };
}

export async function CalculosDaTabelaManual({
  item,
  qtd_entrada,
  val_tabela_str,
  val_desconto_str,
  val_acrescimo_str,
  flg_orgao_publico,
  tipo_tributacao,
  val_reducao_base_calculo,
  cod_situacao_tributaria,
  cod_tributacao,
  per_icms,
  tipo_regime,
  des_sigla,
  cfop,
  des_cfop,
  cfopData,
  bonificado,
  lastIndex,
  produtoSelecionado,
  tribData,
}: CalculosDaTabelaProps): Promise<Produto> {
  let formateditem = {} as Produto;

  let index = lastIndex <= 0 ? 1 : lastIndex + 1;
  if (produtoSelecionado?.num_item) {
    index = produtoSelecionado.num_item;
  }

  const fixos = {
    num_item: index,
    cfop,
    des_cfop,
    cfopData,
    tribData,
    val_reducao_base_calculo,
    per_icms,
    val_frete: 0,
    val_frete_dest: 0,
    flg_destaca_st: false,
    per_cofins: 0,
    val_cofins: 0,
    val_base_cofins: 0,
    per_pis: 0,
    val_pis: 0,
    val_base_pis: 0,
    val_base_ipi: 0,
    val_ipi: 0,
    val_isento_ipi: 0,
    val_outros_ipi: 0,
    val_icms_st: 0,
    val_bc_st: 0,
    val_fcp_icms: 0,
    cod_situacao_tributaria,
    cod_tributacao,
    qtd_total_produto: qtd_entrada,
    flg_bonificado: bonificado,
    tipo_tributacao,
    val_preco: item.val_preco,
  };
  let val_icms_deson = 0;
  const qtd_total = Number(qtd_entrada) * item.qtd_embalagem_venda;
  const { val_tabela, val_desconto, val_acrescimo } = valToNumber(
    val_tabela_str,
    val_desconto_str,
    val_acrescimo_str,
  );

  const val_total = Number(qtd_entrada) * Number(val_tabela);

  const per_fcp_icms = await getPerFCP(item.cod_ncm, des_sigla);

  const val_tabela_liq = parseFloat(
    (qtd_total * (val_tabela - val_desconto + val_acrescimo)).toFixed(2),
  );

  const val_tabela_final = val_tabela_liq * 0; // val_ipi é 0 fixado

  const { val_icms, val_bc_icms, val_outros, val_isento } = CalculaICMS(
    flg_orgao_publico,
    val_tabela_liq || 0,
    val_reducao_base_calculo || 0,
    tipo_tributacao,
    per_fcp_icms || 0,
    item.cod_situacao_tributaria,
    item.flg_ignora_icms_nf_cli,
    tipo_regime,
    item.flg_calcula_st,
    item.flg_prod_propria,
    item.val_icms,
  );

  if ([0, 2].includes(tipo_tributacao) && flg_orgao_publico) {
    val_icms_deson = CalculaValorICMS(
      val_tabela_liq,
      val_icms,
      val_reducao_base_calculo,
      tipo_tributacao,
      false,
      false,
    );
  } else {
    val_icms_deson = 0.0;
  }

  const calculados = {
    per_fcp_icms,
    val_tabela,
    val_desconto,
    val_acrescimo,
    val_tabela_liq,
    val_total,
    val_icms,
    val_bc_icms,
    val_outros,
    val_isento,
    val_icms_deson,
    qtd_total,
    val_tabela_final,
  };

  formateditem = Object.assign(item, fixos, calculados);

  if (flg_orgao_publico) {
    formateditem.val_desconto = formateditem.val_icms;
    formateditem.val_icms = 0;
    formateditem.val_bc_icms = 0;
  }
  return formateditem;
}
