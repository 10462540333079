import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { schema } from './validations/FormDataValidation';
import { schemaFormFinanceiro } from '~/pages/EmissaoNFE/validations/FormFinanceiroValidation';
import { schemaFormItens } from '~/pages/EmissaoNFE/validations/FormItensValidation';

import {
  Loja,
  CuponsReferenciados,
  ParametrosLoja,
  Produto,
  SubTotais,
  ParcelaFinanceiro,
  LogProps,
  ListaDeCFOP,
  LogFilesProps,
} from './protocols';
import { CalculaTotais } from './functions/Calculos';
import useAuth from '~/hooks/useAuth';

import nfe from './defaultData/nfe.json';
import { MasterDetailProps } from '~/utils/masterDetail';

type FormType = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
};

type EmissaoNFEContextData = {
  isUpdate: boolean;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  codLoja: number;
  loja: Loja;
  parceiro: any;
  setCodLoja: React.Dispatch<React.SetStateAction<number>>;
  changeLoja: (data: Loja) => void;
  changeParceiro: (data: any) => void;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  currentTab: string;
  formNFE: FormType;
  formItens: FormType;
  formFinanceiro: FormType;
  setCuponsReferenciados: React.Dispatch<
    React.SetStateAction<CuponsReferenciados[]>
  >;
  cuponsReferenciados: CuponsReferenciados[];
  setParametrosLoja: React.Dispatch<React.SetStateAction<ParametrosLoja>>;
  parametrosLoja: ParametrosLoja;
  produtos: Produto[];
  setProdutos: React.Dispatch<React.SetStateAction<Produto[]>>;
  produtoSelecionado: Produto;
  setProdutoSelecionado: React.Dispatch<React.SetStateAction<Produto>>;
  subTotais: SubTotais;
  setSubTotais: React.Dispatch<React.SetStateAction<SubTotais>>;

  // FINANCEIRO
  parcelasFinanceiro: ParcelaFinanceiro[];
  setParcelasFinanceiro: React.Dispatch<
    React.SetStateAction<ParcelaFinanceiro[]>
  >;
  parcelaSelecionada: ParcelaFinanceiro;
  setParcelaSelecionada: React.Dispatch<
    React.SetStateAction<ParcelaFinanceiro>
  >;
  isFormFinanceiroEditing: any;
  setIsFormFinanceiroEditing: any;

  logs: LogProps[];
  setLogs: React.Dispatch<React.SetStateAction<LogProps[]>>;
  isBonificado: boolean;
  setIsBonificado: React.Dispatch<React.SetStateAction<boolean>>;
  totalFinanceiro: number;
  setTotalFinanceiro: React.Dispatch<React.SetStateAction<number>>;
  resetFormData: () => void;
  isFormItendEditing: any;
  setIsFormItendEditing: React.Dispatch<React.SetStateAction<any>>;
  listaDeCfop: ListaDeCFOP[];
  setListaDeCfop: React.Dispatch<React.SetStateAction<ListaDeCFOP[]>>;
  setExisteLogAcbr: React.Dispatch<React.SetStateAction<LogFilesProps>>;
  existeLogAcbr: LogFilesProps;
  masterDetail: MasterDetailProps[];
  setMasterDetail: Dispatch<SetStateAction<MasterDetailProps[]>>;
  nfManutencao: boolean;
  setNfManutencao: React.Dispatch<React.SetStateAction<boolean>>;
  setNfCancelada: React.Dispatch<React.SetStateAction<boolean>>;
  nfCancelada: boolean;
};

export const EmissaoNFEContext = createContext({} as EmissaoNFEContextData);

interface EmissaoNFEContextProviderProps {
  children: ReactNode;
}

export function EmissaoNFEContextProvider({
  children,
}: EmissaoNFEContextProviderProps): JSX.Element {
  const { user } = useAuth();
  const [isUpdate, setIsUpdate] = useState(false);
  const [currentTab, setCurrentTab] = useState<string>('nfe');
  const [loja, setLoja] = useState({} as Loja);
  const [codLoja, setCodLoja] = useState<number>(0);
  const [parceiro, setParceiro] = useState({} as any);
  const [cuponsReferenciados, setCuponsReferenciados] = useState<
    CuponsReferenciados[]
  >([]);
  const [parametrosLoja, setParametrosLoja] = useState<ParametrosLoja>(
    {} as ParametrosLoja,
  );
  const [produtos, setProdutos] = useState<Produto[]>([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState<Produto>(
    {} as Produto,
  );
  const [subTotais, setSubTotais] = useState<SubTotais>({
    totalNF: 0,
    totalBC: 0,
    totalICMS: 0,
  });

  // FINANCEIRO
  const [parcelasFinanceiro, setParcelasFinanceiro] = useState<
    ParcelaFinanceiro[]
  >([]);
  const [isFormFinanceiroEditing, setIsFormFinanceiroEditing] = useState({
    isEdit: false,
    uuid: null,
  });

  const [parcelaSelecionada, setParcelaSelecionada] =
    useState<ParcelaFinanceiro>({} as ParcelaFinanceiro);
  const [logs, setLogs] = useState<LogProps[]>([]);
  const [isBonificado, setIsBonificado] = useState(false);
  const [totalFinanceiro, setTotalFinanceiro] = useState(0);
  const [isFormItendEditing, setIsFormItendEditing] = useState({
    isEdit: false,
    uuid: null,
  });
  const [listaDeCfop, setListaDeCfop] = useState<ListaDeCFOP[]>([]);
  const [existeLogAcbr, setExisteLogAcbr] = useState<LogFilesProps>({
    logFound: false,
    logs: [],
  });
  const [nfManutencao, setNfManutencao] = useState<boolean>(false);
  const [nfCancelada, setNfCancelada] = useState<boolean>(false);

  const masterDetailDefault = [
    {
      obj_name: 'produtos',
      pk_fields: ['cod_seq_produto'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'financeiro',
      pk_fields: ['cod_seq_parcela'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  function changeLoja(data: any) {
    setLoja(data);
  }
  function changeParceiro(data: any) {
    setParceiro(data);
  }

  /**
   * Form NFE
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  /**
   * Form Itens
   */
  const {
    register: registerFormItens,
    handleSubmit: handleSubmitFormItens,
    control: controlFormItens,
    setValue: setValueFormItens,
    getValues: getValuesFormItens,
    setError: setErrorFormItens,
    setFocus: setFocusFormItens,
    clearErrors: clearErrorsFormItens,
    formState: formStateFormItens,
    watch: watchFormItens,
    reset: resetFormItens,
  } = useForm({
    resolver: yupResolver(schemaFormItens),
    reValidateMode: 'onBlur',
  });
  /**
   * Form Financeiro
   */
  const {
    register: registerFormFinanceiro,
    handleSubmit: handleSubmitFormFinanceiro,
    control: controlFormFinanceiro,
    reset: resetFormFinanceiro,
    setValue: setValueFormFinanceiro,
    getValues: getValuesFormFinanceiro,
    setFocus: setFocusFormFinanceiro,
    formState: formStateFinanceiro,
    watch: watchFormFinanceiro,
    setError: setErrorFormFinanceiro,
    clearErrors: clearErrorsFormFinanceiro,
  } = useForm({
    resolver: yupResolver(schemaFormFinanceiro),
    reValidateMode: 'onChange',
  });

  /**
   * Calcula SubTotais
   */
  useEffect(() => {
    // Recalcula subtotais
    const flg_orgao_publico = getValues('busca_parceiro')?.flg_orgao_publico;
    const flg_calcula_st = getValues('cod_perfil')?.flg_calcula_st;

    const subtotais = CalculaTotais(
      produtos,
      flg_orgao_publico,
      flg_calcula_st,
      loja.tipo_regime,
    );

    // Atualiza state de subtotais e produtos
    setSubTotais(subtotais || 0);
  }, [getValues, loja.tipo_regime, produtos]);

  const resetLoja = useCallback(() => {
    if (user.loja_data) {
      setLoja({
        uf: user.loja_data.des_uf,
        cod_loja: user.loja_data.cod_loja,
        tipo_regime: user.loja_data.tipo_regime,
      });
      setCodLoja(user.loja_data.cod_loja);
      setValue('loja', user.loja_data.cod_loja);
    }
  }, [setValue, user.loja_data]);

  useEffect(() => {
    resetLoja();
  }, [user, resetLoja]);

  const resetFormData = useCallback(() => {
    if (user.loja_data) {
      setLoja({
        uf: user.loja_data.des_uf,
        cod_loja: user.loja_data.cod_loja,
        tipo_regime: user.loja_data.tipo_regime,
      });
      setCodLoja(user.loja_data.cod_loja);
    } else {
      setLoja({} as Loja);
      setCodLoja(0);
    }
    setMasterDetail(masterDetailDefault);
    setProdutos([]);
    setParceiro({} as any);
    setParcelasFinanceiro([]);
    setCuponsReferenciados([]);
    setLogs([]);
    setParametrosLoja({} as ParametrosLoja);
    setParcelaSelecionada({} as ParcelaFinanceiro);
    setProdutoSelecionado({} as Produto);
    setSubTotais({
      totalNF: 0,
      totalBC: 0,
      totalICMS: 0,
    });
    setCurrentTab('nfe');
    resetFormFinanceiro({
      num_nf: '',
      dta_emissao: '',
      dta_entrada: '',
    });
    setIsBonificado(false);
    setProdutoSelecionado({} as Produto);
    setIsFormItendEditing({ isEdit: false, uuid: null });
    resetFormItens();
    resetFormFinanceiro({
      num_condicao: 30,
      condicao: {
        value: 2,
        label: 'DD - DIAS DA DATA',
        cod_condicao: 2,
        des_condicao: 'DIAS DA DATA',
        des_definicao: 'DD',
      },
      finalizadora: '',
      dta_vencimento: '',
      val_financeiro: '',
    });
    reset(nfe);
    resetLoja();
    setNfManutencao(false);
  }, [
    reset,
    resetFormFinanceiro,
    resetFormItens,
    resetLoja,
    user.loja_data,
    masterDetail,
  ]);

  return (
    <EmissaoNFEContext.Provider
      value={{
        loja,
        changeLoja,
        codLoja,
        setCodLoja,
        parceiro,
        changeParceiro,
        currentTab,
        setCurrentTab,
        cuponsReferenciados,
        setCuponsReferenciados,
        parametrosLoja,
        setParametrosLoja,
        formNFE: {
          watch,
          reset,
          control,
          register,
          setValue,
          setError,
          getValues,
          setFocus,
          formState,
          clearErrors,
          handleSubmit,
        },
        formItens: {
          register: registerFormItens,
          handleSubmit: handleSubmitFormItens,
          control: controlFormItens,
          setValue: setValueFormItens,
          getValues: getValuesFormItens,
          setError: setErrorFormItens,
          clearErrors: clearErrorsFormItens,
          setFocus: setFocusFormItens,
          formState: formStateFormItens,
          watch: watchFormItens,
          reset: resetFormItens,
        },
        // FINANCEIRO
        parcelasFinanceiro,
        setParcelasFinanceiro,
        setIsFormFinanceiroEditing,
        isFormFinanceiroEditing,
        formFinanceiro: {
          register: registerFormFinanceiro,
          handleSubmit: handleSubmitFormFinanceiro,
          control: controlFormFinanceiro,
          reset: resetFormFinanceiro,
          setValue: setValueFormFinanceiro,
          getValues: getValuesFormFinanceiro,
          setFocus: setFocusFormFinanceiro,
          formState: formStateFinanceiro,
          watch: watchFormFinanceiro,
          setError: setErrorFormFinanceiro,
          clearErrors: clearErrorsFormFinanceiro,
        },
        produtos,
        setProdutos,
        produtoSelecionado,
        setProdutoSelecionado,
        subTotais,
        setSubTotais,
        isUpdate,
        setIsUpdate,
        parcelaSelecionada,
        setParcelaSelecionada,
        logs,
        setLogs,
        isBonificado,
        setIsBonificado,
        totalFinanceiro,
        setTotalFinanceiro,
        resetFormData,
        isFormItendEditing,
        setIsFormItendEditing,
        listaDeCfop,
        setListaDeCfop,
        existeLogAcbr,
        setExisteLogAcbr,
        masterDetail,
        setMasterDetail,
        nfManutencao,
        setNfManutencao,
        setNfCancelada,
        nfCancelada,
      }}
    >
      {children}
    </EmissaoNFEContext.Provider>
  );
}

export const useEmissaoNFE = (): EmissaoNFEContextData => {
  return useContext(EmissaoNFEContext);
};
