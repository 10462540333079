import React from 'react';
import { EmissaoNFEContent } from './EmissaoNFEContent';
import { EmissaoNFEContextProvider } from './EmissaoNFEContext';
import { TransmissaoProvider } from '~/components/Nfe/TransmissaoNFe/Transmissao';

export const EmissaoNFE: React.FC = () => {
  return (
    <TransmissaoProvider>
      <EmissaoNFEContextProvider>
        <EmissaoNFEContent />
      </EmissaoNFEContextProvider>
    </TransmissaoProvider>
  );
};
