import { transformAsCurrency } from '~/utils/functions';
/**
 * Efetua Cálculo dos campos val_tabela e val_total
 */

interface ValorTabelaEVendaTotaResponse {
  val_tabela: number;
  val_tabela_liq: number;
  val_total: number;
}

export function ValorTabelaEVendaTotal(
  valTotalProduto: number,
  valDesconto: number,
  valAcrescimo: number,
  valVendaPdv: number,
  qtdTotalDeProdutos: number,
): ValorTabelaEVendaTotaResponse {
  let valorVendaPDV: number, valorTabela: number;

  const valorTabelaLiq = valTotalProduto;

  if (valDesconto > 0.0 || valAcrescimo > 0.0) {
    valorVendaPDV = valTotalProduto + valDesconto - valAcrescimo;
    valorTabela = valorVendaPDV / qtdTotalDeProdutos;
  } else {
    valorVendaPDV = valorTabelaLiq;
    valorTabela = valVendaPdv;
  }

  return {
    val_tabela: transformAsCurrency(valorTabela || 0),
    val_tabela_liq: transformAsCurrency(valorTabelaLiq || 0),
    val_total: transformAsCurrency(valorVendaPDV || 0),
  };
}
