import { Produto } from '../../protocols';
import { calculaReducaoBaseCalculo } from './ReducaoBaseCalculo';

interface CalculaTotaisResponse {
  totalNF: number;
  totalBC: number;
  totalICMS: number;
}

export function CalculaTotais(
  itens: Produto[],
  flg_orgao_publico: boolean,
  flg_calcula_st: boolean,
  TipoRegime: number,
): CalculaTotaisResponse {
  let vDebito_ICMS = 0;
  let vBC_ICMS = 0;
  let vTotal_Nota_Fiscal = 0;

  const teste = [];
  for (let i = 0; i < itens.length; i++) {
    teste.push({
      cod_produto: itens[i].cod_produto,
      flg_orgao_publico,
      flg_ignora_icms_nf_cli: itens[i].flg_ignora_icms_nf_cli,
      tipo_tributacao: itens[i].tipo_tributacao,
      TipoRegime,
      flg_calcula_st,
      flg_prod_propria: itens[i].flg_prod_propria,
      val_reducao: itens[i].val_reducao,
    });
    vTotal_Nota_Fiscal += parseFloat(
      (itens[i].val_tabela_liq + itens[i].val_ipi).toFixed(2),
    );

    if (flg_orgao_publico) {
      vDebito_ICMS += itens[i].val_desconto * itens[i].qtd_entrada;
      vBC_ICMS = 0.0;
      vDebito_ICMS = 0.0;
    } else {
      // Débito ICMS
      if (itens[i].flg_ignora_icms_nf_cli !== true) {
        // Base Cálculo ICMS
        switch (itens[i].tipo_tributacao) {
          case 0:
          case 4:
            vBC_ICMS += itens[i].val_tabela_liq;
            break;
          case 2:
            vBC_ICMS += calculaReducaoBaseCalculo(
              itens[i].val_tabela_liq,
              itens[i].val_reducao,
            );
            break;
          default:
            break;
        }
        vDebito_ICMS += itens[i].val_icms;
      }

      if (
        TipoRegime !== 2 &&
        (flg_calcula_st || itens[i].flg_prod_propria) &&
        itens[i].tipo_tributacao === 3
      ) {
        // Débito ICMS
        if (itens[i].flg_ignora_icms_nf_cli !== true) {
          if (itens[i].val_reducao > 0.0) {
            vBC_ICMS += calculaReducaoBaseCalculo(
              itens[i].val_tabela_liq,
              itens[i].val_reducao,
            );
          } else {
            vBC_ICMS += itens[i].val_tabela_liq;
          }

          vDebito_ICMS += itens[i].val_icms;
        }
      }
    }
  }
  return {
    totalNF: vTotal_Nota_Fiscal,
    totalBC: vBC_ICMS,
    totalICMS: vDebito_ICMS,
  };
}
