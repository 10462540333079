import { TruncaFrac } from '../Auxiliares';

export function CalculaValorICMS(
  valor: number,
  icms: number,
  reducao: number,
  tipoTrib: number,
  percentual: boolean,
  trunca: boolean,
): number {
  if ([0, 2, 8].includes(tipoTrib)) {
    const valCredICMS = (valor - valor * (reducao / 100)) * (icms / 100);
    const imposto = parseFloat((icms * (1 - reducao / 100)).toFixed(2));

    if (percentual) {
      if (trunca) return TruncaFrac(imposto, 2);
      return imposto;
    }
    if (trunca) return TruncaFrac(valCredICMS, 2);
    return parseFloat((Math.round(valCredICMS * 100) / 100).toFixed(2));
  }
  return 0;
}
