import { ListaDeCFOP, Produto } from '../../protocols';

export function DefineCFOP(
  listaDeCfop: ListaDeCFOP[],
  TipoTribut: number,
  NF_Cliente: boolean,
  Dentro_Do_Estado: boolean,
  produto: Produto,
): string | ListaDeCFOP {
  const Result = '';
  if (produto) {
    if (produto.cod_produto !== '') {
      if (listaDeCfop.length === 1) {
        return listaDeCfop[0];
      }

      let filteredList = listaDeCfop.filter((cfop) => {
        if (Dentro_Do_Estado) {
          // Dentro do Estado
          return cfop.cfop.startsWith('1') || cfop.cfop.startsWith('5');
        }
        // Fora do Estado
        return (
          cfop.cfop.startsWith('2') ||
          cfop.cfop.startsWith('3') ||
          cfop.cfop.startsWith('6') ||
          cfop.cfop.startsWith('7')
        );
      });

      if (TipoTribut === 3) {
        // Substituição
        filteredList = filteredList.filter((cfop) => {
          return cfop.flg_separa_subst === true || cfop.flg_dif === true;
        });
      } else if (NF_Cliente) {
        filteredList = filteredList.filter((cfop) => {
          return cfop.flg_separa_subst === false;
        });
      } else {
        filteredList = filteredList.filter((cfop) => {
          return cfop.flg_separa_subst === false || cfop.flg_dif === true;
        });
      }

      if (filteredList.length > 1) {
        return '';
      }
      if (filteredList.length === 1) {
        if (TipoTribut === 3) {
          // Substituição
          if (
            filteredList[0].flg_separa_subst === true ||
            filteredList[0].flg_dif === true
          ) {
            return filteredList[0];
          }
          return '';
        }
        // <> de Substituição
        if (filteredList[0].flg_separa_subst === false) {
          return filteredList[0];
        }
      }

      return '';
    }
  }
  return Result;
}
